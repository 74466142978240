/* eslint-disable camelcase */
import clx from 'classnames';
import { ReactNode } from 'react';
import { SIZE } from 'types/utils';
import { CmsImageType } from 'utils/cms/types';

enum DISPLAY_WIDTH_ENUM {
  'very-tight' = 'very-tight',
  tight = 'tight',
  normal = 'normal',
  wide = 'wide',
}

const displayWidthToCSS = {
  'very-tight': 'max-w-[288px] m-auto px-4',
  tight: 'max-w-[792px] m-auto px-4',
  normal: 'max-w-1200 m-auto  px-4',
  wide: '',
};

const GAP_TO_CSS = {
  [SIZE.SM]: 'gap-1',
  [SIZE.MD]: 'gap-8',
  [SIZE.LG]: 'gap-16',
  none: 'gap-0',
};

type WidgetContainerProps = {
  children: ReactNode;
  display_width: DISPLAY_WIDTH_ENUM;
  background_image: CmsImageType;
  gap: SIZE;
  background_color: string;
};

const WidgetContainer = (props: WidgetContainerProps) => {
  const {
    children,
    display_width: displayWidth,
    background_image: backgroundImage,
    gap,
    background_color: backgroundColor,
  } = props;

  return (
    <div
      className={clx(displayWidthToCSS[displayWidth], GAP_TO_CSS[gap], ' w-full bg-center bg-no-repeat flex flex-col')}
      style={{
        backgroundColor: backgroundColor || undefined,
        ...(backgroundImage && {
          backgroundImage: backgroundImage.sizes['1536x1536']
            ? `url(${backgroundImage.sizes['1536x1536']})`
            : undefined,
        }),
      }}
    >
      {children}
    </div>
  );
};

export default WidgetContainer;
